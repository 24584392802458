<template>
  <div
    class="flex flex-col text-a-neutral bg-a-main-gray relative font-sans tracking-wide max-w-full"
  >
    <MainNav />
    <div class="flex flex-col">
      <slot></slot>
    </div>
  </div>
</template>
